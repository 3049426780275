// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apie-mus-jsx": () => import("./../../../src/pages/apie-mus.jsx" /* webpackChunkName: "component---src-pages-apie-mus-jsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontaktai-jsx": () => import("./../../../src/pages/kontaktai.jsx" /* webpackChunkName: "component---src-pages-kontaktai-jsx" */),
  "component---src-pages-paslaugos-tsx": () => import("./../../../src/pages/paslaugos.tsx" /* webpackChunkName: "component---src-pages-paslaugos-tsx" */),
  "component---src-pages-projektai-tsx": () => import("./../../../src/pages/projektai.tsx" /* webpackChunkName: "component---src-pages-projektai-tsx" */),
  "component---src-templates-project-page-tsx": () => import("./../../../src/templates/projectPage.tsx" /* webpackChunkName: "component---src-templates-project-page-tsx" */)
}

