module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"projektai","accessToken":"MC5YX0I4aVJBQUFDWUE1WVdh.77-9Fe-_vUjvv70h77-9JgF-Qe-_vV7vv73vv73vv71XOe-_vXfvv70YXU1J77-9Lh7vv717OO-_vQ","fetchLinks":[],"schemas":{"product":{"Main":{"uid":{"type":"UID","config":{"label":"Uid"}},"main_thumb_image":{"type":"Image","config":{"constraint":{"width":640,"height":640},"thumbnails":[{"name":"supersmall","width":320,"height":320}],"label":"Main thumb image"}},"carousel":{"type":"Group","config":{"fields":{"photo":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Photo"}}},"label":"Carousel"}},"title":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"Project title"}},"description":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","label":"Description"}},"details":{"type":"Group","config":{"fields":{"detail_name":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","label":"Detail name"}},"detail_description":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","label":"Detail description"}}},"label":"Details"}}}},"services":{"Main":{"services":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, rtl","label":"services"}}}}},"lang":"*","prismicToolbar":true,"imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15,"q":50},"typePathsFilenamePrefix":"prismic-typepaths---laumes-web"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon/favicon-32x32.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f7253895132359b1de10deb3704b0d14"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-172148992-1","cookieName":"gatsby-gdpr-google-analytics","anonymize":true},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5MHRNRH","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"pageview"},
    },{
      plugin: require('../node_modules/gatsby-plugin-vercel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
